import React, {MutableRefObject} from "react";
import {CrudTypes, MenuButton, useCrudSubscription, usePreview} from "@cuda-react/core";
import {CreatedIdentityProvider, IdentityProvider} from "../IdentityProviderTypes";
import useDemoApi from "../../../../../hooks/useDemoApi";
import apiResources from "../../../../../apiResources";

interface AddIdentityProviderButtonProps {
    onSelect: (type: IdentityProvider['type']) => () => void;
    refreshRef?: MutableRefObject<(() => void)>;
}
const i18nScope = "tesseract.identity.identityProviders";

const IDP_TOTAL_LIMIT = 10;

export const AddIdentityProviderButton: React.FC<AddIdentityProviderButtonProps> = ({onSelect, refreshRef}) => {
    const isPreview = usePreview('identity1_1');
    const identityApi = useDemoApi(apiResources.identityProvider, apiResources.demoIdentityProviders);
    const [{data},, performFetch] = useCrudSubscription(
        CrudTypes.GET,
        identityApi,
        {pagination: {
            page: 1,
            perPage: 1000,
        }}
    );

    const isLimited = data?.total >= (isPreview? IDP_TOTAL_LIMIT : 1);

    if (refreshRef) {
        refreshRef.current = performFetch;
    }

    const identityProviders = data?.content ?? [];
    const createdTypes = identityProviders.map(({type}: CreatedIdentityProvider) => type);
    const menuItems = [
        {
            label: `${i18nScope}.add.types.entraid`,
            onClick: onSelect('entraid')
        },
        {
            label: `${i18nScope}.add.types.google`,
            onClick: onSelect('google')
        },
        {
            label: `${i18nScope}.add.types.bcc`,
            onClick: onSelect('bcc'),
            disabled: createdTypes.includes('bcc')
        },
        {
            label: `${i18nScope}.add.types.oidc`,
            onClick: onSelect('oidc')
        },
        {
            label: `${i18nScope}.add.types.okta`,
            onClick: onSelect('okta'),
            disabled: createdTypes.includes('okta')
        },
        {
            label: `${i18nScope}.add.types.saml`,
            onClick: onSelect('saml')
        },
        {
            label: `${i18nScope}.add.types.email`,
            onClick: onSelect('email'),
            disabled: createdTypes.includes('email')
        },
    ];

    return (
        <MenuButton
            disabled={isLimited}
            label="tesseract.identity.identityProviders.createIdentityProvider"
            menuItems={menuItems}
        />
    );
};