import React from "react";
import {ConnectedWizard, ResultStep, SubmitStep, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import {CreatedIdentityProvider, IdentityProvider} from "../../IdentityProviderTypes";
import {makeStyles} from "@mui/styles";
import {IdentityProviderSAMLSetup} from "../IdentityProviderSAMLSetup";

type IdentityProviderSAMLFormProps = {
    identityProvider: IdentityProvider;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const i18nScope = "tesseract.identity.identityProviders.add";

const useStyles = makeStyles((theme) => ({
    textarea: {
        "& > div": {
            height: "auto",
            minHeight: theme.spacing(20),
            alignItems: "flex-start"
        },
    },
    narrowLabel: {
        width: 210
    },
}));

interface ShowSAMLDataProps {
    result?: {
        data: CreatedIdentityProvider;
    }
}

const ShowSAMLData = ({result}: ShowSAMLDataProps) => {
    if (!result?.data.saml) {
        return null;
    }

    return (
        <IdentityProviderSAMLSetup identityProvider={result.data} />
    );
};

export const IdentityProviderSAMLForm: React.FC<IdentityProviderSAMLFormProps> = ({
    onSubmit,
    onCancel,
    create,
    identityProvider,
    id,
}: IdentityProviderSAMLFormProps) => {
    const classes = useStyles();

    return (
        <ConnectedWizard
            title={`tesseract.identity.identityProviders.createIdentityProvider`}
            resource={apiResources.identityProvider}
            onClose={onCancel}
            initialValues={identityProvider}
        >
            <SubmitStep label={`${i18nScope}.createStep`}>
                <TextInput
                    source="displayName"
                    label={`${i18nScope}.options.displayName`}
                    isRequired />
                <TextInput
                    source="options.signInEndpoint"
                    label={`${i18nScope}.options.signInEndpoint`}
                    isRequired
                    disabled={!create}
                />
                <TextInput
                    isRequired
                    source="options.signingCert"
                    label={`${i18nScope}.options.signingCert`}
                    inputClasses={{
                        textField: classes.textarea,
                    }}
                    inputLabelProps={{
                        classes: {
                            inputLabel: classes.narrowLabel
                        }
                    }}
                    options={{
                        multiline: true
                    }}
                />
            </SubmitStep>
            <ResultStep
                label={`${i18nScope}.setupStep`}
                onClose={onSubmit}
            >
                <ShowSAMLData />
            </ResultStep>
        </ConnectedWizard>
    );
};