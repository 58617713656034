import {DialogBody, LoadingMessage, useCrudProps} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import React from "react";
import {UserDirectoryForm} from "./UserDirectoryForm/UserDirectoryForm";

interface EditUserDirectoryWrapperInterface {
    id: string;
    onClose: () => void;
    onSuccess: () => void;
}

export const EditUserDirectoryWrapper = ({id, onClose, onSuccess}: EditUserDirectoryWrapperInterface) => {
    const [{data: directory}] = useCrudProps(apiResources.directories, {id});

    if (!directory){
        return <LoadingMessage />;
    }

    return (
        <DialogBody title="tesseract.identity.userDirectories.editDirectory" onClose={onClose} form>
            <UserDirectoryForm
                create={false}
                id={id}
                directory={directory}
                onClose={(refresh) => {
                    if (refresh) {
                        onSuccess();
                    }
                    onClose();
                }}
            />
        </DialogBody>
    );
};