import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    ActionButtonsField,
    Card,
    ConnectedTable,
    ConnectedTableRefresh,
    CrudTypes,
    CustomField,
    DeleteDialog,
    OK_NOTIFICATION,
    TOAST_NOTIFICATION,
    useCrudFetch,
    useGlobalParam,
    useMixpanel,
    useNotifications,
} from '@cuda-react/core';
import {useLocation} from 'react-router';
import {parse} from 'query-string';
import {IdentityProviderMenu} from './components/IdentityProviderMenu';
import apiResources from '../../../../apiResources';
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import {CreatedIdentityProvider, IdentityProvider, IdentityProviderType} from './IdentityProviderTypes';
import {Dialog} from '@barracuda-internal/bds-core';
import {useTranslation} from 'react-i18next';
import {IdentityProviderForm} from './components/IdentityProviderForm/IdentityProviderForm';
import {IDPAuthorizationStatusField} from './components/IDPAuthorizationStatusField';
import {EditIdentityProviderWrapper} from './components/EditIdentityProviderWrapper';
import {IdentityProvidersNoData} from './components/IdentityProvidersNoData';
import {styled} from '@mui/styles';
import useDemoApi from "../../../../hooks/useDemoApi";
import {AddIdentityProviderButton} from './components/AddIdentityProviderButton';

const MinHeightCard = styled(Card)({
    minHeight: 'calc(50vh - 90px)',
});

const i18nScope = "tesseract.identity.identityProviders";

const initialValue: IdentityProvider = {
    displayName: "",
    type: "email",
    accountName: "",
} as IdentityProvider;

const initialOptions: Record<IdentityProviderType, any> = {
    oidc: {
        discoveryEndpoint: "",
        clientId: "",
        clientSecret: "",
        scope: "",
    },
    entraid: {
        tenantId: "",
    },
    email: {},
    bcc: {},
    saml: {
        signInEndpoint: '',
        signingCert: '',
    },
    google: {
        googleWorkspaceDomain: '',
    },
    okta: {
        oktaDomainName: '',
        oktaClientId: '',
        oktaClientSecret: '',
    }
};

export const IdentityProviders = () => {
    const registerAction = useMixpanel('Identity Providers');
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const addButtonRefreshRef = useRef<() => void>(null);
    const location = useLocation();
    const [showConsentToast] = useNotifications(
        TOAST_NOTIFICATION,
        'tesseract.identityProviders.addNotification',
        OK_NOTIFICATION
    );
    const [translate] = useTranslation();

    const [account] = useGlobalParam("pageTitle.account");
    const accountName = useMemo(() => account?.join?.(" - "), [account]);

    const identityApi = useDemoApi(apiResources.identityProvider, apiResources.demoIdentityProviders);
    const [,, performCreate] = useCrudFetch(CrudTypes.CREATE, identityApi);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [initialFormValue, setInitialFormValue] = useState<IdentityProvider>({
        ...initialValue,
        type: 'bcc',
        options: {},
    });

    useEffect(() => {
        const queryString = parse(location.search);

        if (queryString?.consent === 'true') {
            showConsentToast();
        }
    }, []);


    const handleCreateClose = useCallback((wasCreated: boolean) => {
        if (wasCreated) {
            registerAction('created');
            tableRefreshRef.current?.();
            addButtonRefreshRef.current?.();
        }
        setDialogOpen(false);
    }, [registerAction, tableRefreshRef, setDialogOpen]);

    const handleIDPCreation = useCallback((type: IdentityProviderType) => async () => {
        const newIdentityProvider = {
            ...initialValue,
            accountName,
            type,
            options: {
                ...initialOptions[type]
            }
        };

        if (type === 'bcc' || type === 'email') {
            newIdentityProvider['displayName'] = {
                'bcc': 'Barracuda Cloud Control',
                'email': 'Email',
            }[type];


            await performCreate({
                data: newIdentityProvider
            });

            tableRefreshRef.current?.();
            addButtonRefreshRef.current?.();

            return;
        }

        setInitialFormValue(newIdentityProvider);
        setDialogOpen(true);
    }, [
        initialValue,
        setInitialFormValue,
        setDialogOpen,
        performCreate,
        tableRefreshRef,
        accountName,
    ]);

    return (
        <>
            <MinHeightCard id="identity-providers" title={`${i18nScope}.title`}>
                <ConnectedTable
                    hasTitle
                    refreshRef={tableRefreshRef}
                    resource={identityApi}
                    pollInterval={300000}
                    actions={[
                        <AddIdentityProviderButton
                            key={0}
                            onSelect={handleIDPCreation}
                            refreshRef={addButtonRefreshRef as MutableRefObject<() => void>}
                        />
                    ]}
                    noDataPage={<IdentityProvidersNoData onSelect={handleIDPCreation} />}
                >
                    <CustomField
                        source='name'
                        label={`${i18nScope}.table.name`}
                        render={(value, data) => data?.displayName || data?.name}
                    />
                    <CustomField
                        source='type'
                        label={`${i18nScope}.table.type`}
                        render={(value, data) => translate(`${i18nScope}.add.types.${data.type}`)}
                    />
                    <CustomField
                        source='status'
                        label={`${i18nScope}.table.status`}
                        render={(status: CreatedIdentityProvider['status'], identityProvider: CreatedIdentityProvider) =>
                            <IDPAuthorizationStatusField status={status} id={identityProvider.id}/>
                        }
                    />
                    <ActionButtonsField source='id' alwaysVisible>
                        <IdentityProviderMenu/>
                            <CreateEditButtonDialog
                                create={false}
                                component={EditIdentityProviderWrapper}
                                disabled={(idp: any) => idp.type === 'bcc' || idp.type === 'email'}
                                disabledMessage={(idp: any) => translate(`${i18nScope}.cantEditIdentityProvider`, {type: idp.type === 'bcc' ? 'BCC' : 'Email'})}
                                onSuccess={() => {
                                    tableRefreshRef.current?.();
                                    addButtonRefreshRef.current?.();
                                } } />
                            <DeleteDialog
                                resource={apiResources.identityProvider}
                                title={`${i18nScope}.delete.title`}
                                message={`${i18nScope}.delete.body`}
                                onSuccess={() => {
                                    registerAction('deleted');
                                    tableRefreshRef?.current?.();
                                    addButtonRefreshRef?.current?.();
                                }}
                            />
                    </ActionButtonsField>
                </ConnectedTable>
            </MinHeightCard>
            <Dialog
                open={dialogOpen}
                onClose={handleCreateClose}
                maxWidth={false}
                disableEnforceFocus
            >
                <IdentityProviderForm
                    create
                    identityProvider={initialFormValue}
                    onClose={handleCreateClose}
                />
            </Dialog>
        </>
    );
};