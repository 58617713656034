import React, {useRef} from "react";

import {
    ActionButtonsField,
    ConnectedTableRefresh,
    CustomField,
    TablePage,
    TextField,
    useMixpanel,
    DeleteDialog,
    usePreview,
} from "@cuda-react/core";

import {useTranslation} from "react-i18next";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import {CreateEditGroup} from './components/CreateEditGroup';
import {capitalize} from "lodash";
import {ChipMembersField} from '../components/ChipMembersField';

export const Groups = () => {
    const [translate] = useTranslation();
    const groupsApi = useDemoApi(apiResources.identityGroups, apiResources.demoGroups);
    const registerAction = useMixpanel('Groups');
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const isPreview = usePreview('identity1_1');

    return (
        <TablePage
            authenticated
            pageMode="paginate"
            title="tesseract.identity.pageTitle"
            subtitle="tesseract.identity.groups.title"
            resource={groupsApi}
            noDataMessage="tesseract.identity.groups.noDataMessage"
            tableName="tesseract.identity.groups.tableName"
            refreshRef={tableRefreshRef}
            actions={[
                isPreview && <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditGroup}
                    type="trustedRoot"
                    label="tesseract.identity.groups.create"
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                />
            ]}
        >
            <TextField
                source="displayName"
                label="tesseract.identity.groups.name"
                filter="text"
                sortable
            />
            { isPreview ? (
                <CustomField
                    source="type"
                    label="tesseract.identity.groups.type"
                    render={(type: string) => capitalize(type)}
                    filter="select"
                    filterProps={{
                        choices: [{key: "local", name: "Local"}, {key: "external", name: "External"}]
                    }}
                />
            ) : null}
            <TextField
                source="directory.name"
                label="tesseract.identity.groups.directory"
                defaultText="-"
            />
            <CustomField
                source="id"
                label="tesseract.identity.groups.members"
                render={(id) => <ChipMembersField resource={apiResources.identityUsers} params={{filter: {groups: id}}} />}
            />
            <TextField
                source="description"
                label="tesseract.identity.groups.description"
            />
            { isPreview ? (
                <ActionButtonsField
                    width={142}
                    source="id"
                    alwaysVisible
                >
                    <CreateEditButtonDialog
                        key="edit"
                        component={CreateEditGroup}
                        label="tesseract.identity.groups.cantEditExternalGroups"
                        onSuccess={() => {
                            registerAction('Update');
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.identityGroups}
                        title="tesseract.identity.groups.delete.title"
                        disabledMessage="tesseract.identity.groups.cantDeleteExternalGroups"
                        message={
                            <p>
                                {translate("tesseract.identity.groups.delete.body")}
                            </p>
                        }
                        onSuccess={() => {
                            registerAction('Deleted');
                            tableRefreshRef.current?.();
                        }}
                    />

                </ActionButtonsField>
            ) : null}
        </TablePage>
    );
};