import React, {useRef} from "react";
import {ConnectedTableRefresh, CustomField, TablePage, TextField, useMixpanel,DeleteDialog, usePreview, ActionButtonsField} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import {CreateEditUser} from './components/CreateEditUser';
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {ChipMembersField} from "../components/ChipMembersField";
import {CreatedUser} from "./Users.types";

export const Users = () => {
    const [translate] = useTranslation();
    const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);
    const registerAction = useMixpanel('Users');
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const isPreview = usePreview('identity1_1');

    return (
        <TablePage
            authenticated
            pageMode="paginate"
            title="tesseract.identity.pageTitle"
            subtitle="tesseract.identity.users.title"
            resource={usersApi}
            noDataMessage="tesseract.identity.users.noDataMessage"
            tableName="tesseract.identity.users.tableName"
            refreshRef={tableRefreshRef}
            actions={[
                isPreview && <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditUser}
                    label="tesseract.identity.users.create"
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                />
            ]}
        >
            <TextField
                source="name"
                label="tesseract.identity.users.name"
                filter="text"
                sortable
                filterProps={{
                    label: "tesseract.identity.users.nameOrEmail"
                }}
            />
            <TextField
                source="email"
                label="tesseract.identity.users.email"
            />
            <CustomField
                source="groups"
                label="tesseract.identity.users.groups"
                render={(type: string, user: CreatedUser) => <ChipMembersField data={user.groups} />}
                filter="selectarray"
                filterProps={{
                    resource: apiResources.identityGroups,
                    optionValue: "id",
                }}
            />
            <TextField
                source="directory.name"
                label="tesseract.identity.users.directory"
                defaultText="-"
            />
            {
                isPreview ? (
                    <CustomField
                        source="type"
                        label="tesseract.identity.users.type"
                        render={(type: string) => capitalize(type)}
                        filter="select"
                        filterProps={{
                            choices: [{key: "local", name: "Local"}, {key: "external", name: "External"}]
                        }}
                    />
                ) : null
            }
            {
                isPreview ? (
                    <ActionButtonsField
                        width={142}
                        source="id"
                        alwaysVisible
                    >
                        <CreateEditButtonDialog
                            key="edit"
                            component={CreateEditUser}
                            label="tesseract.identity.users.edit"
                            disabled={({type}) => type === "external"}
                            tooltipDisabled={({type}: CreatedUser) => type === "external"}
                            disabledMessage="tesseract.identity.users.cantEditExternalUsers"
                            onSuccess={() => {
                                registerAction("Updated");
                                tableRefreshRef.current?.();
                            }}
                        />
                        <DeleteDialog
                            resource={apiResources.identityUsers}
                            title="tesseract.identity.users.delete.title"
                            disabledMessage="tesseract.identity.users.cantDeleteExternalUsers"
                            message={
                                <p>
                                    {translate("tesseract.identity.users.delete.body")}
                                </p>
                            }
                            onSuccess={() => {
                                registerAction('Deleted');
                                tableRefreshRef.current?.();
                            }}
                        />

                    </ActionButtonsField>
                ) : null
            }
        </TablePage>
    );
};